import React from 'react';
import './roadmap.css';
import Navbar from "../Navbar/Navbar.js";

const Roadmap = () => {
    return <>
        <Navbar />

    </>
}

export default Roadmap;

